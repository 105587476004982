import Enumeration, {Enum} from '@/library/enumerations/Enumeration';

@Enum
export default class Role extends Enumeration {
    static translationKey = 'role';

    static ADMIN = 1;
    static EDITOR = 2;
    static PITCHER = 3;
}
