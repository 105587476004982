import {OnboardingStep} from '@/store/onboarding';
import Role from '@/library/enumerations/Role';

/**
 * The onboarding steps for a team user.
 */
const teamUserSteps: OnboardingStep[] = [
    {
        completed: true,
        completionKey: 'createProfile',
        titleKey: 'onboarding.steps.createProfile',
    },
    {
        completed: true,
        completionKey: 'createTeamProfile',
        role: Role.ADMIN,
        titleKey: 'onboarding.steps.createTeamProfile',
    },
    {
        completionKey: 'startPitch',
        nextOnCompleted: true,
        route: {
            name: 'home',
        },
        titleKey: 'onboarding.steps.startPitch',
    },
    {
        completionKey: 'watchPitchIntroduction',
        nextOnCompleted: true,
        titleKey: 'onboarding.steps.watchPitchIntroduction',
    },
    {
        completionKey: 'readyToGo',
        titleKey: 'onboarding.steps.readyToGo',
    },
];

/**
 * The onboarding steps for an organisation user.
 */
const organisationUserSteps: OnboardingStep[] = [
    {
        completed: true,
        completionKey: 'createProfile',
        titleKey: 'onboarding.steps.createProfile',
    },
    {
        completed: true,
        completionKey: 'createOrganisationProfile',
        titleKey: 'onboarding.steps.createOrganisationProfile',
    },
    {
        completionKey: 'createPitchMoment',
        route: {
            name: 'home',
        },
        titleKey: 'onboarding.steps.createPitchMoment',
    },
    {
        completionKey: 'inviteTeam',
        nextOnCompleted: true,
        route: {
            name: 'teams.index',
        },
        titleKey: 'onboarding.steps.inviteTeam',
    },
    {
        completionKey: 'readyToGo',
        titleKey: 'onboarding.steps.readyToGo',
    },
];

export {
    teamUserSteps,
    organisationUserSteps,
};
