import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39b76a87"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_Error = _resolveComponent("Error")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "form-label",
          for: _ctx.inputName
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["position-relative", _ctx.imageClass])
    }, [
      (_ctx.previewImage)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass(["rounded", _ctx.imageClass]),
            src: _ctx.previewImage
          }, null, 10, _hoisted_2))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["p-6 rounded text-white bg-light", _ctx.imageClass])
          }, [
            _createVNode(_component_Fa, {
              class: "w-100 h-100",
              icon: "upload"
            })
          ], 2)),
      _createElementVNode("label", {
        class: "btn-image-field-action bg-semi-transparent cursor-pointer transition position-absolute top-0 end-0 bottom-0 start-0 rounded d-flex align-items-center justify-content-center",
        for: _ctx.inputName
      }, [
        _createVNode(_component_Fa, {
          class: "text-white",
          icon: "pencil-large",
          size: "3x"
        })
      ], 8, _hoisted_3),
      _createElementVNode("input", {
        id: _ctx.inputName,
        accept: "image/*",
        hidden: "",
        type: "file",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openImage && _ctx.openImage(...args)))
      }, null, 40, _hoisted_4),
      (_ctx.previewImage)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "btn-image-field-action btn transition p-2 position-absolute top-0 end-0",
            "data-test-id": "btn-remove",
            type: "button",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.removeImage && _ctx.removeImage(...args)))
          }, [
            _createVNode(_component_Fa, {
              class: "text-danger",
              icon: "trash"
            })
          ]))
        : _createCommentVNode("", true)
    ], 2),
    _createVNode(_component_Error, {
      class: "d-block",
      "error-key": _ctx.errorKey,
      errors: _ctx.errors
    }, null, 8, ["error-key", "errors"])
  ]))
}