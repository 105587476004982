import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "mt-4 text-black-50 text-center" }
const _hoisted_3 = { class: "d-grid mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageField = _resolveComponent("ImageField")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_BtnResource = _resolveComponent("BtnResource")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t(`${_ctx.type}.create.title`)), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t(`${_ctx.type}.create.body`)), 1),
    _createElementVNode("form", {
      class: "mt-12",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.saveTeam && _ctx.saveTeam(...args)), ["prevent"]))
    }, [
      _createVNode(_component_ImageField, {
        class: "mt-12",
        errors: _ctx.team.errors,
        label: _ctx.$t(`${_ctx.type}.logo`),
        "model-value": _ctx.image,
        name: "logo",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.newImage = $event))
      }, null, 8, ["errors", "label", "model-value"]),
      _createVNode(_component_FormGroup, {
        modelValue: _ctx.team.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.team.name) = $event)),
        class: "mt-12",
        "error-key": "name",
        errors: _ctx.team.errors,
        label: _ctx.$t(`${_ctx.type}.name`),
        name: "name"
      }, null, 8, ["modelValue", "errors", "label"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BtnResource, { resource: _ctx.team }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('actions.next')), 1)
          ]),
          _: 1
        }, 8, ["resource"])
      ])
    ], 32)
  ], 64))
}