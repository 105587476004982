
import {PropType, defineComponent} from 'vue';
import {DefaultConversions} from '@/models/Media';
import Error from '@/components/common/Error.vue';
import Errors from '@/library/model-collection/Errors';
import get from 'lodash/get';

type ImageValueType = string | DefaultConversions;

/**
 * Creates an image input field.
 * Shows the current image value with a hover state icon to upload a new image.
 * Can be passed a string or an object of conversions as the model value.
 * Emits 'update:modelValue' when the image value has changed.
 */
export default defineComponent({
    components: {Error},
    props: {
        errorKey: {
            type: [String, Array],
            default: () => (['profilePicture', 'logo']),
        },
        errors: {
            type: Object as PropType<Errors>,
            default: () => ({}),
        },
        label: {
            type: String,
            default: null,
        },
        modelValue: {
            type: [String, Object] as PropType<ImageValueType>,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: null,
            validator: (value: string) => ['sm', 'lg'].includes(value),
        },
        valueKey: {
            type: String,
            default: 'original',
        },
    },
    emits: [
        'update:modelValue',
    ],
    computed: {
        previewImage(): string | undefined {
            if (!this.modelValue) {
                return undefined;
            }

            return typeof this.modelValue === 'object'
                ? get(this.modelValue, this.valueKey)
                : this.modelValue;
        },
        inputName(): string | null {
            return this.name
                || this.label
                || null;
        },
        imageClass(): string[] {
            const classes = ['image'];

            if (this.size) {
                classes.push(`image-${this.size}`);
            }

            return classes;
        },
    },
    methods: {
        removeImage() {
            this.$emit('update:modelValue', null);
        },
        openImage(event: Event) {
            const newImage = (event.target as HTMLInputElement).files![0];

            if (!newImage) {
                return;
            }

            const reader = new FileReader();

            reader.onload = () => {
                this.$emit('update:modelValue', reader.result);
            };

            reader.readAsDataURL(newImage);
        },
    },
});
