
import {PropType, defineComponent, ref} from 'vue';
import {organisationUserSteps, teamUserSteps} from '@/components/introduction/OnboardingSteps';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import {DefaultConversions} from '@/models/Media';
import FormGroup from '@/components/common/form/FormGroup.vue';
import ImageField from '@/components/common/form/ImageField.vue';
import Team from '@/models/Team';
import TeamType from '@/library/enumerations/TeamType';
import onboardingStore from '@/store/onboarding';

export default defineComponent({
    components: {
        BtnResource,
        FormGroup,
        ImageField,
    },
    props: {
        baseTeam: {
            type: Object as PropType<Team>,
            required: true,
        },
        teamType: {
            type: Number as PropType<TeamType>,
            required: true,
        },
    },
    setup(props) {
        return {
            team: ref(props.baseTeam),
            newImage: ref<string>(''),
        };
    },
    computed: {
        image(): string | DefaultConversions | undefined {
            return this.newImage
                ? this.newImage
                : this.team.logo;
        },
        type(): string {
            switch (this.teamType) {
                case TeamType.ORGANISATION:
                    return 'organisations';
                default:
                    return 'teams';
            }
        },
    },
    mounted() {
        if (this.team.completedAt) {
            this.assignOnboardingSteps();

            onboardingStore.showOnboarding.value = true;

            this.$router.push({name: 'home'});
        }
    },
    methods: {
        assignOnboardingSteps() {
            if (this.teamType === TeamType.ORGANISATION) {
                onboardingStore.setOnboardingSteps(organisationUserSteps, this.team.currentTeamUser);
            } else {
                onboardingStore.setOnboardingSteps(teamUserSteps, this.team.currentTeamUser);
            }
        },
        async saveTeam() {
            if (this.newImage) {
                this.team.logo = {
                    original: this.newImage,
                };
            }

            try {
                await this.team.update();
            } catch (e: any) {
                if (!e.response || !e.response.data.errors) throw e;

                return;
            }

            this.assignOnboardingSteps();

            await this.$router.push(this.team.getLocation('register.invitations'));
        },
    },
});
